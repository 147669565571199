<template>
  <a-drawer width="80%" :label-col="4" :wrapper-col="14" :visible="open" @close="onClose">
    <a-divider orientation="left">
      <b>{{ formTitle }}</b>
    </a-divider>
    <a-form-model ref="form" :model="form" :rules="rules">
      <a-form-model-item label="分销商id" prop="userId" >
        <a-input v-model="form.userId" placeholder="请输入分销商id" />
      </a-form-model-item>
      <a-form-model-item label="分销链接" prop="inviteUrl" >
        <a-input v-model="form.inviteUrl" placeholder="请输入分销链接" />
      </a-form-model-item>
      <a-form-model-item label="邀请码" prop="inviteCode" >
        <a-input v-model="form.inviteCode" placeholder="请输入邀请码" />
      </a-form-model-item>
      <a-form-model-item label="邮箱" prop="userEmail" >
        <a-input v-model="form.userEmail" placeholder="请输入邮箱" />
      </a-form-model-item>
      <a-form-model-item label="手机号" prop="userPhone" >
        <a-input v-model="form.userPhone" placeholder="请输入手机号" />
      </a-form-model-item>
      <a-form-model-item label="分销比例" prop="distributorRait" >
        <a-input v-model="form.distributorRait" placeholder="请输入分销比例" />
      </a-form-model-item>
      <a-form-model-item label="银行名称" prop="bankName" >
        <a-input v-model="form.bankName" placeholder="请输入银行名称" />
      </a-form-model-item>
      <a-form-model-item label="银行卡号" prop="bankNo" >
        <a-input v-model="form.bankNo" placeholder="请输入银行卡号" />
      </a-form-model-item>
      <a-form-model-item label="真实姓名" prop="realName" >
        <a-input v-model="form.realName" placeholder="请输入真实姓名" />
      </a-form-model-item>
      <a-form-model-item label="开户行地址" prop="bankAddress" >
        <a-input v-model="form.bankAddress" placeholder="请输入开户行地址" />
      </a-form-model-item>
      <a-form-model-item label="状态 0-正常 1-禁用" prop="status" >
      </a-form-model-item>
      <a-form-model-item label="地址" prop="address" >
        <a-input v-model="form.address" placeholder="请输入地址" />
      </a-form-model-item>
      <a-form-model-item label="备注" prop="remark" >
        <a-input v-model="form.remark" placeholder="请输入内容" type="textarea" allow-clear />
      </a-form-model-item>
      <div class="bottom-control">
        <a-space>
          <a-button type="primary" :loading="submitLoading" @click="submitForm">
            保存
          </a-button>
          <a-button type="dashed" @click="cancel">
            取消
          </a-button>
        </a-space>
      </div>
    </a-form-model>
  </a-drawer>
</template>

<script>
import { getDistributor, addDistributor, updateDistributor } from '@/api/user/distributorData'

export default {
  name: 'CreateForm',
  props: {
  },
  components: {
  },
  data () {
    return {
      submitLoading: false,
      formTitle: '',
      // 表单参数
      form: {
        id: null,

        userId: null,

        inviteUrl: null,

        inviteCode: null,

        userEmail: null,

        userPhone: null,

        distributorRait: null,

        bankName: null,

        bankNo: null,

        realName: null,

        bankAddress: null,

        status: 0,

        address: null,

        createTime: null,

        remark: null,

      },
      // 1增加,2修改
      formType: 1,
      open: false,
      rules: {
        userId: [
          { required: true, message: '分销商id不能为空' }
        ]
      }
    }
  },
  filters: {
  },
  created () {
  },
  computed: {
  },
  watch: {
  },
  mounted () {
  },
  methods: {
    onClose () {
      this.open = false
    },
    // 取消按钮
    cancel () {
      this.open = false
      this.reset()
    },
    // 表单重置
    reset () {
      this.formType = 1
      this.form = {
        id: null,
        userId: null,
        inviteUrl: null,
        inviteCode: null,
        userEmail: null,
        userPhone: null,
        distributorRait: null,
        bankName: null,
        bankNo: null,
        realName: null,
        bankAddress: null,
        status: 0,
        address: null,
        createTime: null,
        remark: null,
      }
      this.$nextTick(() => {
        if(this.$refs.form){
          this.$refs.form.resetFields()
        }
      })
    },
    /** 新增按钮操作 */
    handleAdd (row) {
      this.reset()
      this.formType = 1
      this.open = true
      this.formTitle = '添加'
    },
    /** 修改按钮操作 */
    handleUpdate (row, ids) {
      this.reset()
      this.formType = 2
      const id = row ? row.id : ids
      getDistributor({"id":id}).then(response => {
        this.form = response.data
        this.open = true
        this.formTitle = '修改'
      })
    },
    /** 提交按钮 */
    submitForm: function () {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.submitLoading = true
          if (this.form.id !== undefined && this.form.id !== null) {
            updateDistributor(this.form).then(response => {
              this.$message.success(
                '修改成功',
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          } else {
            addDistributor(this.form).then(response => {
              this.$message.success(
                '新增成功',
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          }
        } else {
          return false
        }
      })
    }
  }
}
</script>
